import React from "react";
import { withRouter, Link } from "react-router-dom";
import "./index.css";
import Login from "./Login";
import TheBigDayContent from "./TheBigDayContent";
import { checkAvailability } from "../../firebaseConfig";
import LoadingSvg from './LoadingSvg';

class TheBigDay extends React.Component {
  constructor() {
    super();
    this.state = {
      rsvpCode: localStorage.getItem("rsvp-code"),
      loading: true,
      isValid: false
    };
  }

  componentDidMount() {
    this.checkCodeValidity(this.state.rsvpCode);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.rsvpCode !== this.state.rsvpCode) {
      this.checkCodeValidity(this.state.rsvpCode);
    }
  }

  setLoadingVisibility(val) {
    this.setState({
      loading: val
    });
  }

  async checkCodeValidity(code) {
    if (!code) {
      this.setState({
        loading: false,
        isValid: false
      });
    } else {
      this.setLoadingVisibility(true);
      let isValid = await checkAvailability(code || '')
      this.setState({
        isValid: isValid,
        loading: false
      });
    }
  }

  render() {
    const { isValid, rsvpCode, loading } = this.state;
    let show = false;
    if (this.props.location.pathname.match(/the-big-day/)) {
      show = true;
    }

    return (
      <div id="the-big-day-wrapper" className={"main-content-wrapper " + (show ? "" : "hidden")}>
        <div className="content-container">
          <Link to={"/"} className="close">
            close
          </Link>
        </div>
        <div className="content" id="main-scroller">
          {loading ? (
            <div className="loading">
              <LoadingSvg />
            </div>
          ) : isValid ? (
            <TheBigDayContent code={rsvpCode} />
          ) : (
            <Login
              setLoadingVisibility={val => this.setLoadingVisibility(val)}
              successfulLogin={() => this.setState({ isValid: true })}
              invalidLogin={() => this.setState({isValid: false})}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(TheBigDay);
